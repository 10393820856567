<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\list.vue
  FILE_NAME : list
  AUTHOR : bykim
  DATE : 2023-12-19
  DESCRIPTION : 근무유형설정 메인 화면
-->
<template>
  <div>
    <div class="page-sub-box">
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @saving="onSaving" @init-new-row="onInitNewRow" />
    </div>
  </div>
</template>

<script>
  import { isSuccess } from '@/plugins/common-lib';

  import { DxSwitch } from 'devextreme-vue/switch';
  import ArrayStore from 'devextreme/data/array_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid },
    props: {},
    data() {
      return {
        extraAllowanceList: [],
        dataGrid: {
          keyExpr: 'workTypeId', // 그리드 키값
          refName: 'workTypeGridRef', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: true, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 50, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          apiActionNm: {
            select: 'EWM_WORK_TYPE_LIST',
          }, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: true, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            saving: true, // 저장 이벤트
            initNewRow: true, // 신규 행 추가 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDeleteData();
                  },
                },
                location: 'before',
              },
            ], // 그리드 커스텀 버튼 생성
          },
          isDuplicateConfigKey: false, // 설정키 중복 체크
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: false, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: false, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'allPages', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: true, // 전체 선택 허용 여부
          },
          columns: [
            {
              caption: '근무종류',
              dataField: 'workCategory',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              lookup: {
                dataSource: this.$_enums.ewm.workCategory.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '근무장소',
              dataField: 'workLocationCd',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              lookup: {
                dataSource: this.$_enums.ewm.workLocation.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '근무명',
              dataType: 'string',
              dataField: 'workNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'left',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              validationRules: [
                {
                  type: 'required',
                  message: '필수값 입니다.',
                },
              ],
            },
            {
              multiHeaderNm: '요일설정',
              columns: [
                {
                  caption: '월',
                  dataField: 'mondayWorkCd',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowEditing: true,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  lookup: {
                    dataSource: this.$_enums.ewm.workStatus.values,
                    displayExpr: 'label',
                    valueExpr: 'value',
                  },
                  cellTemplate: (container, options) => this.dayCellTemplate(container, options),
                },
                {
                  caption: '화',
                  dataField: 'tuesdayWorkCd',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowEditing: true,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  lookup: {
                    dataSource: this.$_enums.ewm.workStatus.values,
                    displayExpr: 'label',
                    valueExpr: 'value',
                  },
                  cellTemplate: (container, options) => this.dayCellTemplate(container, options),
                },
                {
                  caption: '수',
                  dataField: 'wednesdayWorkCd',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowEditing: true,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  lookup: {
                    dataSource: this.$_enums.ewm.workStatus.values,
                    displayExpr: 'label',
                    valueExpr: 'value',
                  },
                  cellTemplate: (container, options) => this.dayCellTemplate(container, options),
                },
                {
                  caption: '목',
                  dataField: 'thursdayWorkCd',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowEditing: true,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  lookup: {
                    dataSource: this.$_enums.ewm.workStatus.values,
                    displayExpr: 'label',
                    valueExpr: 'value',
                  },
                  cellTemplate: (container, options) => this.dayCellTemplate(container, options),
                },
                {
                  caption: '금',
                  dataField: 'fridayWorkCd',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowEditing: true,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  lookup: {
                    dataSource: this.$_enums.ewm.workStatus.values,
                    displayExpr: 'label',
                    valueExpr: 'value',
                  },
                  cellTemplate: (container, options) => this.dayCellTemplate(container, options),
                },
                {
                  caption: '토',
                  dataField: 'saturdayWorkCd',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowEditing: true,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  lookup: {
                    dataSource: this.$_enums.ewm.workStatus.values,
                    displayExpr: 'label',
                    valueExpr: 'value',
                  },
                  cellTemplate: (container, options) => this.dayCellTemplate(container, options),
                },
                {
                  caption: '일',
                  dataField: 'sundayWorkCd',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowEditing: true,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  lookup: {
                    dataSource: this.$_enums.ewm.workStatus.values,
                    displayExpr: 'label',
                    valueExpr: 'value',
                  },
                  cellTemplate: (container, options) => this.dayCellTemplate(container, options),
                },
              ],
            },
            {
              multiHeaderNm: '근무',
              columns: [
                {
                  dataType: 'datetime',
                  format: 'HH:mm',
                  caption: '시작시간',
                  dataField: 'workStartTime',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowEditing: true,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  editorOptions: {
                    interval: 5,
                    type: 'time',
                    pickerType: 'list',
                    dateSerializationFormat: 'HH:mm',
                  },
                  validationRules: [
                    {
                      type: 'required',
                      message: '필수값 입니다.',
                    },
                  ],
                },
                {
                  dataType: 'datetime',
                  format: 'HH:mm',
                  caption: '근무시간',
                  dataField: 'workTimeStr',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowEditing: true,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  editorOptions: {
                    interval: 5,
                    type: 'time',
                    pickerType: 'list',
                    dateSerializationFormat: 'HH:mm',
                  },
                  validationRules: [
                    {
                      type: 'required',
                      message: '필수값 입니다.',
                    },
                  ],
                },
              ],
            },
            {
              dataType: 'datetime',
              format: 'HH:mm',
              caption: '휴게시간',
              dataField: 'restTimeStr',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              editorOptions: {
                interval: 5,
                type: 'time',
                pickerType: 'list',
                dateSerializationFormat: 'HH:mm',
              },
              validationRules: [
                {
                  type: 'required',
                  message: '필수값 입니다.',
                },
              ],
            },
            {
              dataType: 'datetime',
              format: 'HH:mm',
              caption: '지각기준',
              dataField: 'tardyTime',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              editorOptions: {
                interval: 5,
                type: 'time',
                pickerType: 'list',
                dateSerializationFormat: 'HH:mm',
              },
              validationRules: [
                {
                  type: 'required',
                  message: '필수값 입니다.',
                },
              ],
            },
            {
              caption: '주간 총 근무시간',
              dataField: 'weekWorkTotalTime',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowFiltering: false,
            },
            {
              caption: '휴일근무여부',
              dataField: 'holidayWorkAssignmentFl',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.holidayWorkAssignment.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              multiHeaderNm: '추가수당',
              columns: [
                {
                  caption: '여부',
                  dataField: 'eaFl',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowEditing: true,
                  lookup: {
                    dataSource: this.$_enums.common.stringUsedFlag.values,
                    displayExpr: 'label',
                    valueExpr: 'value',
                  },
                  setCellValue: (rowData, value) => {
                    if (value === this.$_enums.common.stringUsedFlag.NO.value) {
                      rowData.eaFl = value;
                      rowData.eaId = null; // 추가수당>구분 초기화
                      rowData.eaValue = null; // 추가수당>수당배율 초기화
                    } else {
                      rowData.eaFl = value;
                    }
                  },
                },
                {
                  caption: '구분',
                  dataField: 'eaId',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  allowEditing: true,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                  lookup: {
                    dataSource: [],
                    displayExpr: 'label',
                    valueExpr: 'value',
                  },
                  setCellValue: (rowData, value) => {
                    rowData.eaId = value;
                    this.extraAllowanceList.forEach(item => {
                      if (item.id === value) {
                        rowData.eaValue = item.allowanceValue;
                      }
                    });
                  },
                },
                {
                  caption: '수당배율',
                  dataField: 'eaValue',
                  alignment: 'center',
                  visible: true,
                  sortOrder: 'none',
                  disabled: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                  allowFiltering: false,
                },
              ],
            },
            {
              caption: '표시여부',
              dataField: 'viewFl',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ], // 컬럼 정보
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 셀렉트 박스 초기화 */
      initData() {
        this.setExtraAllowance(); // 추가수당>구분 셀렉트 박스 데이터 초기화
      },
      async setExtraAllowance() {
        const dataList = await this.selectExtraAllowance();
        this.dataGrid.columns[9].columns[1].lookup.dataSource = new ArrayStore({
          key: 'id',
          data: dataList,
        });
      },
      /**
       * @description : 추가수당>구분 셀렉트 박스 데이터 조회
       * */
      async selectExtraAllowance() {
        const payload = {
          actionname: 'EWM_WORK_INFO_EA_LIST_ALL',
          data: {},
          loading: false,
        };
        const res = await this.CALL_EWM_API(payload);
        let lookupDataList = [];
        if (isSuccess(res)) {
          res.data.data.forEach(item => {
            lookupDataList.push({
              value: item.id,
              label: item.allowanceNm,
            });
          });
          this.extraAllowanceList = res.data.data;
          return lookupDataList;
        } else {
          return [];
        }
      },
      async onDeleteData() {
        const vm = this;
        const selectedRowsData = this.$refs.workTypeGridRef.selectedRowsData;
        if (selectedRowsData?.length) {
          if (
            await this.$_Confirm(
              this.$_msgContents('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }),
            )
          ) {
            let wtIdList = [];
            selectedRowsData.forEach(row => {
              wtIdList.push(row['workTypeId']);
            });

            const payload = {
              actionname: 'EWM_WORK_TYPE_LIST_DELETE',
              data: { wtIdList: wtIdList },
              useErrorPopup: true,
            };
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              this.$refs.workTypeGridRef.refreshData();
            } else {
              this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          }
        } else {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }
      },
      /**
       * @description : 요일 셀렉트 박스 템플릿
       * */
      dayCellTemplate(container, options) {
        let element = document.createElement('div');
        element.innerHTML = options.text;
        element.style.fontWeight = 'bold';

        if (options.text === this.$_enums.ewm.workStatus.WORK_DAY.label) {
          element.style.color = '#089027';
        } else if (options.text === this.$_enums.ewm.workStatus.DAY_OFF.label) {
          element.style.color = '#f7665f';
        }
        container.append(element);
      },
      /**
       * @description : 사용여부 셀렉트 박스 템플릿
       * */
      useFlagCellTemplate(container, options, colNm) {
        const switchBtn = new DxSwitch({
          propsData: {
            value: options.value === this.$_enums.common.stringUsedFlag.YES.value,
            onValueChanged: e => {
              if (e.value) {
                options.component.cellValue(options.rowIndex, colNm, this.$_enums.common.stringUsedFlag.YES.value);
              } else {
                options.component.cellValue(options.rowIndex, colNm, this.$_enums.common.stringUsedFlag.NO.value);
              }
            },
          },
        });
        switchBtn.$mount();
        container.append(switchBtn.$el);
      },
      /**
       * @description : 저장 이벤트
       * */
      async onSaving(e) {
        if (await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
          e.cancel = true; // false 셋팅하면 grid에 binding된 data가 변경되어버림

          // 변경된 값 존재 여부 체크 && 데이터 그리드 액션명 설정 여부 체크
          let data = [];
          e.changes.forEach(d => {
            let keyExpr = this.dataGrid.keyExpr ? this.dataGrid.keyExpr : 'id'; // ex) id
            let dataKey = d.key; // ex) 1
            let dataMap = d.data; // ex) { value: 100, name: 'test' }

            // 수정/신규/병합 타입 확인 후 데이터 맵에 등록자/수정자 정보 추가
            if (d.type === 'update') {
              e.component
                .byKey(dataKey)
                .then(data => {
                  dataMap = Object.assign(data, d.data);
                })
                .catch(error => {
                  this.$log.error(error);
                });
            } else {
              dataKey = null;
            }
            dataMap[keyExpr] = dataKey; // ex) { id: 1, value: 100, name: 'test' }
            data.push(dataMap); // ex) [{ id: 1, value: 100, name: 'test' }]
          });

          const payload = {
            actionname: 'EWM_WORK_TYPE_MERGE',
            data: data,
            useErrorPopup: true,
          };

          const res = await this.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            e.component.cancelEditData();
            this.$refs.workTypeGridRef.refreshData();
          }
        }
      },
      /**
       * @description : 신규 행 추가 이벤트
       * */
      onInitNewRow(e) {
        // 근무종류 기본값 설정
        e.data.workCategory = this.$_enums.ewm.workCategory.BASIC.value;
        // 근무유형 기본값 설정
        e.data.workLocationCd = this.$_enums.ewm.workLocation.CENTER.value;
        // 요일설정 기본값 설정
        e.data.mondayWorkCd = this.$_enums.ewm.workStatus.NON_WORK_DAY.value;
        e.data.tuesdayWorkCd = this.$_enums.ewm.workStatus.NON_WORK_DAY.value;
        e.data.wednesdayWorkCd = this.$_enums.ewm.workStatus.NON_WORK_DAY.value;
        e.data.thursdayWorkCd = this.$_enums.ewm.workStatus.NON_WORK_DAY.value;
        e.data.fridayWorkCd = this.$_enums.ewm.workStatus.NON_WORK_DAY.value;
        e.data.saturdayWorkCd = this.$_enums.ewm.workStatus.NON_WORK_DAY.value;
        e.data.sundayWorkCd = this.$_enums.ewm.workStatus.NON_WORK_DAY.value;
        // 표시여부
        e.data.viewFl = this.$_enums.common.stringUsedFlag.YES.value;
        // 추가수당 여부
        e.data.eaFl = this.$_enums.common.stringUsedFlag.NO.value;
        // 휴일근무여부
        e.data.holidayWorkAssignmentFl = this.$_enums.ewm.holidayWorkAssignment.ALL_INCLUDED.value;
        e.data.restTimeStr = '00:00';
        e.data.tardyTime = '00:00';
      },
    },
    created() {
      this.initData();
    },
    mounted() {},
  };
</script>

<style lang="scss" scoped>
</style>
